<script>
import api from '@/helpers/apirest/api';
import { debounce } from 'lodash';

export default {
    name: 'SelectAutocompleteField',
    emits: ['change'],
    props: {
        value: {
            type: [Number, String, Array, Object],
        },
        onlyIds: {
            type: Boolean,
            default: false
        },
        initialOptions: {
            type: Array,
            default: () => []
        },
        validationName: Object,
        field: {
            type: String,
            default: 'id'
        },
        label: {
            type: String,
            default: 'name'
        },
        id: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        },
        taggable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: null
        },
        onChangePayload: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            localParams: this.params,
            options: this.initialOptions,
            loading: false,
            currentPage: 1,
            lastPage: null,
            scrollPosition: 0,
            observer: null
        };
    },
    computed: {
        selectedOption() {
            if(this.onlyIds){
                if (this.multiple) {
                    return this.options.filter(option => (this.value) ? this.value.includes(option[this.field]) : false);
                }
                return this.options.find(option => option[this.field] === this.value) || null;
            }

            return this.value;
        }
    },
    methods: {
        updateValue(option) {
            if(this.onlyIds){
                if (this.multiple) {
                    this.$emit('input', option ? option.map(option => option[this.field]) : []);
                    this.$emit('change', option, this.onChangePayload);
                } else {
                    this.$emit('input', option ? option[this.field] : null);
                    this.$emit('change', option, this.onChangePayload);
                }
            }else{
                this.$emit('input', option);
                this.$emit('change', option, this.onChangePayload);
            }
            
            
        },
        onOpen() {
            if (this.options.length === 0) {
                this.onSearch('', (status) => { this.loading = status; }, true);
            }
        },
        onSearch(search, loading, force = false) {
            if (search.length || force) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: debounce(async (loading, search, vm) => {
            try {
                vm.currentPage = 1;
                await vm.getData(search);
            } catch (error) {
                console.error('Error in search:', error);
            } finally {
                loading(false);
            }
        }, 350),
        async getData(search = '', extraParams = {}) {
            if (this.lastPage && this.currentPage > this.lastPage) return;

            if(this.$el){
                const scrollContainer = this.$el.querySelector('.vs__dropdown-menu');
                this.scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;
            }

            const res = await api.get({
                url: this.url,
                params: {
                    ...this.localParams,
                    filter: search,
                    per_page: 30,
                    page: this.currentPage,
                    ...extraParams
                }
            });
            const newOptions = res?.data?.data || [];
            if (this.currentPage === 1) {
                this.options = newOptions;
            } else {
                this.options = [...this.options, ...newOptions];
            }
            this.lastPage = res?.data?.meta?.last_page;
            this.currentPage += 1;

            this.$nextTick(() => {
                setTimeout(() => {
                    if(this.$el){
                        const scrollContainer = this.$el.querySelector('.vs__dropdown-menu');
                        if (scrollContainer) {
                            scrollContainer.scrollTop = this.scrollPosition;
                        }
                    }
                }, 50);
            });
        },
        async loadMore() {
            if (this.loading || (this.lastPage && this.currentPage > this.lastPage)) return;
            this.loading = true;
            await this.getData();
            this.loading = false;
        },
        onScroll(event) {
            const container = event.target;
            if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
                this.loadMore();
            }
        },
        observeDropdown() {
            this.observer = new MutationObserver(() => {
                const scrollContainer = this.$el.querySelector('.vs__dropdown-menu');
                if (scrollContainer) {
                    scrollContainer.addEventListener('scroll', this.onScroll);
                }
            });

            this.observer.observe(this.$el, {
                childList: true,
                subtree: true,
            });
        },
        createOption(newOption) {
            const tagOption = { [this.field]: null, [this.label]: newOption };
            return tagOption;
        }
    },
    mounted() {
        this.observeDropdown();
    },
    beforeDestroy() {
        if (this.observer) this.observer.disconnect();
        const scrollContainer = this.$el.querySelector('.vs__dropdown-menu');
        if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', this.onScroll);
        }
    },
    watch: {
        value: {
            immediate: true,
            async handler(value) {
                if (value && this.onlyIds && this.options?.length === 0) {
                    try {
                        await this.getData('', {
                            [this.field]: value
                        });
                    } catch (error) {
                        console.error('Error in search:', error);
                    }
                }
            }
        },
        params: {
            handler(newVal) {
                if (JSON.stringify(newVal) !== JSON.stringify(this.localParams)) {
                    this.localParams = { ...newVal };
                    this.options = [];
                    this.currentPage = 1;
                    this.lastPage = null;
                    this.$emit('input', null);
                }
            },
            deep: true
        }
    }
};
</script>

<template>
    <!-- <v-select :options="options" :multiple="multiple" @search="onSearch" @open="onOpen" :label="label" -->
    <v-select :options="options" :multiple="multiple" :taggable="taggable" @search="onSearch" @open="onOpen" 
        :label="label" :value="selectedOption" @input="updateValue" :placeholder="placeholder"
        :create-option="createOption"
        :disabled="disabled"
        >
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                No se encontró resultados para <em>{{ search }}</em>.
            </template>
            <em v-else-if="loading" style="opacity: 0.5"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cargando lista...</em>
            <em v-else style="opacity: 0.5">Sin resultados</em>
        </template>
    </v-select>
</template>

<style scoped>
.loader {
    text-align: center;
    color: #bbbbbb;
}
</style>
